import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  MarkerClusterer
} from "@react-google-maps/api";
import Button from "components/Button";
import InputText from "components/InputText";
import Wrapper from "components/Wrapper";
import { useLocale } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import React, { ChangeEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import {
  compareDistances,
  getDistanceFromLatLonInKm
} from "utils/common/store-locator";
import StarIcon from "components/icons/Star";

export interface Store {
  id: string;
  name: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  featured?: boolean;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

const StoreLocator = ({ stores }: { stores: Store[] }) => {
  // State variables
  const [googleApi, setGoogleApi] = useState();
  const [map, setMap] = useState();
  const [zoom, setZoom] = useState(5);
  const [searchString, setSearchString] = useState("");
  const [selectedStore, setSelectedStore] = useState();
  const [distanceReference, setDistanceReference] = useState<Coordinate>();
  const [mapCenter, setMapCenter] = useState<Coordinate>({
    lat: (stores && stores[0] && stores[0].coordinates.latitude) || 0,
    lng: (stores && stores[0] && stores[0].coordinates.longitude) || 0
  });

  // Context
  const { locale } = useLocale();
  const { formatMessage } = useTranslation();

  /**
   * Utility method to find the current location
   */
  const geolocate = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setMapCenter(center);
        setZoom(12);
        setDistanceReference(center);
      });
    }
  };

  /**
   * Utility method to retrive lat long coordinates from a string
   */
  const geocode = (e: MouseEvent) => {
    e.preventDefault();
    if (googleApi) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: searchString }, result => {
        if (result && result.length > 0) {
          const center = {
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng()
          };
          setMapCenter(center);
          setZoom(12);
          setDistanceReference(center);
        }
      });
    }
  };

  const renderGoogleMap = () => (
    <GoogleMapContainer>
      <BackgroundBox />
      <GoogleMap
        id="marker-example"
        mapContainerStyle={{
          height: "100%",
          width: "100%"
        }}
        zoom={zoom}
        center={mapCenter}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        }}
        onLoad={setMap}
        onZoomChanged={() => map && setZoom(map.zoom)}
      >
        <MarkerClusterer
          options={{
            imagePath:
              "https://unpkg.com/googlemaps-v3-utility-library@1.0.1/markerclusterer/images/m"
          }}
        >
          {clusterer =>
            stores.map(store => (
              <div key={store.id}>
                <Marker
                  position={{
                    lat: store.coordinates.latitude,
                    lng: store.coordinates.longitude
                  }}
                  onClick={() => setSelectedStore(store)}
                  clusterer={clusterer}
                />
                {googleApi && selectedStore && store.id === selectedStore.id && (
                  <InfoWindow
                    options={{
                      pixelOffset: new googleApi.maps.Size(0, -50)
                    }}
                    position={{
                      lat: store.coordinates.latitude,
                      lng: store.coordinates.longitude
                    }}
                    onCloseClick={() => setSelectedStore(undefined)}
                  >
                    <InfoWindowContainer>
                      <InfoWindowStoreName>{store.name}</InfoWindowStoreName>
                      <InfoWindowStoreStreet>
                        {store.address}
                      </InfoWindowStoreStreet>
                      <InfoWindowStoreArea>
                        <InfoWindowStoreCity>{store.city}</InfoWindowStoreCity>
                        {store.zipCode && (
                          <InfoWindowStoreZip>
                            , {store.zipCode}
                          </InfoWindowStoreZip>
                        )}
                        {store.country && (
                          <InfoWindowStoreCountry>
                            , {store.country}
                          </InfoWindowStoreCountry>
                        )}
                      </InfoWindowStoreArea>
                      <InfoViewOnMap
                        href={`https://www.google.com/maps?daddr=${
                          store.coordinates.latitude
                        },${store.coordinates.longitude}`}
                        target="_blank"
                      >
                        <FormattedMessage id="label.seeOnGoogleMaps" />
                      </InfoViewOnMap>
                    </InfoWindowContainer>
                  </InfoWindow>
                )}
              </div>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </GoogleMapContainer>
  );

  return (
    <Wrapper>
      <Container>
        <Stores>
          <InputAddress>
            <SearchTitle>
              <FormattedMessage id="label.location" />:
            </SearchTitle>
            <Search>
              <InputText
                type="text"
                placeholder={formatMessage({ id: "label.address" })}
                value={searchString}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchString(e.target.value)
                }
              />
              <Button
                onClick={geocode}
                disabled={!googleApi || searchString === ""}
              >
                <FormattedMessage id="label.search" />
              </Button>
            </Search>
            <GeolocateText>
              <FormattedMessage
                id="label.geolocate"
                values={{
                  geolocateLink: (
                    <Geolocate onClick={geolocate}>
                      <FormattedMessage id="label.geolocateLink" />
                    </Geolocate>
                  )
                }}
              />
            </GeolocateText>
          </InputAddress>
          <ListItem>
            {(distanceReference
              ? stores.sort(
                  ({ coordinates: c1 }: Store, { coordinates: c2 }: Store) =>
                    compareDistances(
                      c1.latitude,
                      c1.longitude,
                      c2.latitude,
                      c2.longitude,
                      distanceReference.lat,
                      distanceReference.lng
                    )
                )
              : stores
            ).map(store => {
              const distance = distanceReference
                ? getDistanceFromLatLonInKm(
                    store.coordinates.latitude,
                    store.coordinates.longitude,
                    distanceReference.lat,
                    distanceReference.lng
                  )
                : undefined;
              return (
                <Item
                  key={`${store.id} - ${store.name}`}
                  selected={selectedStore && store.id === selectedStore.id}
                  onClick={() => {
                    setSelectedStore(store);
                    setZoom(15);
                    setMapCenter({
                      lat: store.coordinates.latitude,
                      lng: store.coordinates.longitude
                    });
                  }}
                >
                  {store.featured && (
                    <FeaturedLogo>
                      <StarIcon />
                    </FeaturedLogo>
                  )}
                  <Name>{store.name}</Name>
                  <Address>{store.address}</Address>
                  <City>
                    <CityName>{store.city}</CityName>
                    {store.zipCode && <ZipCode>, {store.zipCode}</ZipCode>}
                    {store.country && <Country>, {store.country}</Country>}
                  </City>
                  <Directions>
                    {distance && distance > 0 && (
                      <Distance>{distance.toFixed(2)} Km</Distance>
                    )}
                    <ViewOnMap
                      href={`https://www.google.com/maps?daddr=${
                        store.coordinates.latitude
                      },${store.coordinates.longitude}`}
                      target="_blank"
                    >
                      <FormattedMessage id="label.seeOnGoogleMaps" />
                    </ViewOnMap>
                  </Directions>
                </Item>
              );
            })}
          </ListItem>
        </Stores>

        <LoadScript
          googleMapsApiKey="AIzaSyDMgrlomo6vXd-L1KCV_WMX1u4L86R63Mo"
          language={locale}
          loadingElement={
            <GoogleMapContainer>
              <BackgroundBox />
            </GoogleMapContainer>
          }
          onLoad={() =>
            // @ts-ignore
            window && window.google && setGoogleApi(window.google)
          }
        >
          {renderGoogleMap()}
        </LoadScript>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    margin-top: 40px;
  }
  > div:nth-of-type(3) {
    position: relative;
    height: 600px;
    width: 800px;
    @media (max-width: 1240px) {
      width: calc(100% - 400px);
    }
    @media (max-width: 1000px) {
      width: 100%;
      height: 400px;
      margin-bottom: 80px;
    }
  }
`;

const Stores = styled.div`
  position: relative;
  width: 400px;
  height: 600px;
  padding-right: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0;
    height: auto;
  }
`;

const SearchTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333;
  font-weight: 600;
  padding-bottom: 15px;
`;

const InputAddress = styled.div`
  padding-bottom: 30px;
  @media (max-width: 1000px) {
    width: 60%;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 750px) {
    width: 100%;
    text-align: left;
  }
`;

const Search = styled.form`
  display: flex;
  ${InputText} {
    font-size: 14px;
    margin-bottom: 15px;
  }
  ${Button} {
    width: 160px;
  }
  @media (max-width: 500px) {
    flex-direction: column;

    ${Button} {
      width: 100%;
      margin-bottom: 10px;
      max-width: 100%;
    }
  }
`;

const GeolocateText = styled.div`
  font-size: 14px;
  letter-spacing: 0.06em;
`;

const Geolocate = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ListItem = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    width: calc(100% + 20px);
    margin-left: -10px;
    overflow: auto;
  }
`;

const Item = styled.li<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  background-color: ${({ theme }) => theme.colors.background2};
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 1.8em;
  width: 100%;
  border: ${({ selected, theme }) =>
    selected
      ? `2px solid ${theme.colors.main}`
      : `2px solid ${theme.colors.background2}`};
  transition: 0.3s all;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    margin: 10px;
    width: calc(50% - 20px);
  }
  @media (max-width: 750px) {
    margin: 10px;
    width: calc(100% - 20px);
  }
`;

const FeaturedLogo = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 15px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Name = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 600;
  padding-bottom: 10px;
`;

const Address = styled.div``;

const City = styled.div`
  display: flex;
`;

const Directions = styled.div``;

const CityName = styled.div``;

const ZipCode = styled.div``;

const Country = styled.div``;

const Distance = styled.div`
  color: ${({ theme }) => theme.colors.main};
  display: inline-block;
  margin-right: 10px;
`;

const ViewOnMap = styled.a`
  color: ${({ theme }) => theme.colors.main};
  display: inline-block;
  &:hover {
    font-weight: 600;
  }
`;

const GoogleMapContainer = styled.div``;

const BackgroundBox = styled.div`
  position: absolute;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.background2};
  height: calc(100% + 80px);
  width: calc((100vw - 1200px) / 2 + (100% - 100px));
  padding: 40px;
  top: -40px;
  left: 100px;

  @media (max-width: 1240px) {
    width: calc(100% - 100px + 20px);
  }
  @media (max-width: 750px) {
    width: calc(60% + 20px);

    left: 40%;
  }
`;

const InfoWindowContainer = styled.div`
  font-family: soleil;
  padding: 10px 10px;
`;

const InfoWindowStoreName = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 600;
  padding-bottom: 10px;
  color: #977b2b;
`;

const InfoWindowStoreStreet = styled.div``;

const InfoWindowStoreArea = styled.div`
  display: flex;
`;

const InfoWindowStoreCity = styled.div``;

const InfoWindowStoreZip = styled.div``;

const InfoWindowStoreCountry = styled.div``;

const InfoViewOnMap = styled.a`
  color: ${({ theme }) => theme.colors.main};
  display: inline-block;
  padding-top: 5px;
  &:hover {
    font-weight: 600;
  }
`;

export default StoreLocator;
