import React from "react";
import PropTypes from "prop-types";

const Star = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      stroke={color}
      {...otherProps}
      x="0px"
      y="0px"
    >
      <polygon points="12,2 15.1,8.3 22,9.3 17,14.1 18.2,21 12,17.8 5.8,21 7,14.1 2,9.3 8.9,8.3 " />
    </svg>
  );
};

Star.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Star.defaultProps = {
  color: "#977b2b",
  size: "24"
};

export default Star;
